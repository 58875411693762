<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <Main>
        <PageTitle label="Resource Not Found" />
        <div class="content">
            <section>
                <SadCloud />
                <div class="center">
                    <p class="bold">You have recieved a visit from the sad cloud...</p>
                    <p>He says the resource you were looking for does not exist.</p>
                    <p>It may have been moved or it may have expired.</p>
                </div>
            </section>
            <Button
                :action="goToHome"
                title="Go to Home"
            >
                Go to Home
            </Button>
        </div>
    </Main>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Main from '@/components/generic/Main';
        import Button from '@/components/generic/Button';
        import SadCloud from '@/components/generic/SadCloud';
        import PageTitle from '@/components/generic/PageTitle';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Main,
            Button,
            SadCloud,
            PageTitle
        };

    //

    // METHODS

        // Go to home.
        const goToHome = function () {

            // Update router.
            this.$router.replace({
                name: 'landing'
            });

        };

        // Define methods.
        const methods = {
            goToHome
        };

    //

    // EXPORT

        export default {
            name: 'ResourceNotFound',
            components,
            methods
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        section {
            @extend .standard-container;
        }

    //

    // CLASSES

        .content {
            @extend .content-center;
        }

        .center {
            text-align: center;
            margin: 1rem 0 2rem;
            font-size: 1.10rem;
            font-weight: 300;
            display: grid;
            * {
                margin: .25rem;
            }
        }

        .bold {
            font-weight: 500;
            letter-spacing: .05rem;
            font-size: 1.25rem;
        }

    //

</style>
