<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <main>
        <slot />
    </main>
</template>


<script>

    // EXPORT

        export default {
            name: 'Main'
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        main {
            display: grid;
            margin-top: $header-height;
            min-height: $page-height;
            padding: .5rem .5rem 3.5rem;
            align-items: flex-start;
            align-content: flex-start;
        }

    //

</style>
