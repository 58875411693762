<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <button
        :title="title"
        @click="handleClick"
    >
        <slot />
        {{ label }}
    </button>
</template>


<script>

    // PROPS

        // Define props.
        const props = {
            label: {
                type: String
            },
            action: {
                type: Function,
                required: true
            },
            title: {
                type: String,
                required: true
            }
        };

    //

    // METHODS

        // Handle click.
        const handleClick = function () {

            // Invoke the action parameter.
            this.action();

        };

        // Define methods.
        const methods = {
            handleClick
        };

    //

    // EXPORT

        export default {
            name: 'Button',
            props,
            methods
        };

    //

</script>
